.brk-gallery .no-gutters .brk-gallery-card {
  margin-bottom: 0; }

.brk-gallery_masonry .brk-gallery-card {
  position: absolute;
  left: 15px;
  top: 15px;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  margin-bottom: 30px; }
  @media screen and (max-width: 576px) {
    .brk-gallery_masonry .brk-gallery-card {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; } }

@media screen and (max-width: 576px) {
  .brk-gallery_masonry .brk-gallery-card {
    margin-left: 0;
    margin-right: 0; } }

.brk-gallery-card {
  margin-bottom: 30px;
  position: relative;
  overflow: hidden; }
  @media screen and (max-width: 576px) {
    .brk-gallery-card {
      margin-left: -15px;
      margin-right: -15px; } }
  .brk-gallery-card:before {
    content: "";
    padding-top: 100%;
    display: block; }
  .brk-gallery-card_style-1:before {
    padding-top: 270px; }
  .brk-gallery-card__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }
  .brk-gallery-card_grayscale .brk-gallery-card__img {
    filter: grayscale(100%); }
  .brk-gallery-card_overflow {
    overflow: visible; }
  .brk-gallery-card__add-1, .brk-gallery-card__add-2, .brk-gallery-card__add-3, .brk-gallery-card__add-4, .brk-gallery-card__add-5, .brk-gallery-card__add-6 {
    position: absolute;
    height: 25%;
    width: 0;
    background-color: #ffffff;
    transition: .3s ease width; }
  .brk-gallery-card__add-5, .brk-gallery-card__add-6 {
    height: 50%; }
  .brk-gallery-card__add-1, .brk-gallery-card__add-2, .brk-gallery-card__add-5 {
    left: 0; }
  .brk-gallery-card__add-3, .brk-gallery-card__add-4, .brk-gallery-card__add-6 {
    right: 0; }
  .brk-gallery-card__add-1, .brk-gallery-card__add-3 {
    top: 0; }
  .brk-gallery-card__add-2, .brk-gallery-card__add-4 {
    top: 75%; }
  .brk-gallery-card__add-5, .brk-gallery-card__add-6 {
    top: 25%; }
  .brk-gallery-card:hover .brk-gallery-card__add-1, .brk-gallery-card:hover .brk-gallery-card__add-2, .brk-gallery-card:hover .brk-gallery-card__add-3, .brk-gallery-card:hover .brk-gallery-card__add-4 {
    width: 25%; }
  .brk-gallery-card:hover .brk-gallery-card__add-5, .brk-gallery-card:hover .brk-gallery-card__add-6 {
    animation-name: galleryanimation;
    animation-timing-function: ease;
    animation-duration: .6s; }
  .brk-gallery-card__overlay {
    position: absolute;
    top: 50%;
    left: 25%;
    height: 0;
    width: 50%;
    transition: .3s ease height, .3s ease width .3s, .3s ease top, .3s ease left .3s;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4); }
  .brk-gallery-card__static-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .brk-gallery-card:hover .brk-gallery-card__overlay {
    height: 50%;
    width: 100%;
    top: 25%;
    left: 0; }
  .brk-gallery-card__fancy {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform .3 ease; }
  .brk-gallery-card:hover .brk-gallery-card__fancy {
    transform: translate(-50%, -50%) scale(1); }
  .brk-gallery-card__central-btn {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: .3s ease transform;
    font-size: 16px !important; }
  .brk-gallery-card:hover .brk-gallery-card__central-btn {
    transform: translate(-50%, -50%) scale(1); }
  .brk-gallery-card_shadow {
    transform: translateY(0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.26);
    transition: .3s ease transform, .3s ease box-shadow; }
    .brk-gallery-card_shadow:hover {
      transform: translateY(-25px);
      box-shadow: 0 30px 30px rgba(0, 0, 0, 0.26); }
  .brk-gallery-card__overlay-full {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: .3s ease opacity; }
  .brk-gallery-card:hover .brk-gallery-card__overlay-full {
    opacity: 1; }
  .brk-gallery-card__angle-btn {
    position: absolute;
    bottom: -85px;
    right: -85px;
    width: 84px;
    height: 84px;
    clip-path: polygon(0 100%, 100% 100%, 100% 0);
    transition: .3s ease right, .3s ease bottom; }
    [dir="rtl"] .brk-gallery-card__angle-btn {
      clip-path: polygon(0 100%, 100% 100%, 0 0); }
  .brk-gallery-card:hover .brk-gallery-card__angle-btn {
    right: -1px;
    bottom: -1px; }
    [dir="rtl"] .brk-gallery-card:hover .brk-gallery-card__angle-btn {
      right: auto;
      left: -1px; }
  .brk-gallery-card__angle-btn-top {
    position: absolute;
    top: -85px;
    right: -85px;
    width: 84px;
    height: 84px;
    clip-path: polygon(0 0, 100% 100%, 100% 0);
    transition: .3s ease right, .3s ease top; }
    [dir="rtl"] .brk-gallery-card__angle-btn-top {
      clip-path: polygon(0 0, 0 100%, 100% 0); }
  .brk-gallery-card:hover .brk-gallery-card__angle-btn-top {
    right: -1px;
    top: -1px; }
    [dir="rtl"] .brk-gallery-card:hover .brk-gallery-card__angle-btn-top {
      right: auto;
      left: -1px; }
  .brk-gallery-card__overlay-angle {
    position: absolute;
    width: 1000%;
    height: 1000%;
    left: -1000%;
    top: -1000%;
    transform: rotate(45deg);
    transition: .7s ease left, .7s ease top;
    background-color: rgba(21, 21, 21, 0.76); }
  .brk-gallery-card:hover .brk-gallery-card__overlay-angle {
    left: -500%;
    top: -500%; }
  .brk-gallery-card__overlay-angle-top {
    position: absolute;
    width: 1000%;
    height: 1000%;
    left: -1000%;
    bottom: -1000%;
    transform: rotate(45deg);
    transition: .7s ease left, .7s ease bottom; }
  .brk-gallery-card:hover .brk-gallery-card__overlay-angle-top {
    left: -500%;
    bottom: -500%; }
  .brk-gallery-card__fancy_angle {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }
  .brk-gallery-card__angle-btn, .brk-gallery-card__angle-btn-top {
    box-shadow: 0 5px 16px rgba(var(--brand-primary-rgb), 0.5);
    background-image: linear-gradient(to left, var(--brk-base-2) 0%, var(--brk-base-5) 100%); }

@keyframes galleryanimation {
  50% {
    width: 25%; }
  100% {
    width: 0; } }

.brk-filters_style-3 .brk-filters__item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 11px 40px;
  border-radius: 25px;
  overflow: hidden;
  cursor: pointer; }
  .brk-filters_style-3 .brk-filters__item::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    z-index: -1;
    opacity: 0;
    transition: .3s ease opacity;
    background-image: linear-gradient(to right, var(--brand-primary), var(--secondary)); }
  .brk-filters_style-3 .brk-filters__item::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    border: 2px solid rgba(0, 0, 0, 0.06);
    transition: .3s ease all;
    background-color: #ffffff; }
  .brk-filters_style-3 .brk-filters__item > * {
    z-index: 1; }
  .brk-filters_style-3 .brk-filters__item i {
    margin-right: 10px; }
  .brk-filters_style-3 .brk-filters__item:last-child {
    margin-right: 0; }
  .brk-filters_style-3 .brk-filters__item.active::after {
    opacity: 1; }
  .brk-filters_style-3 .brk-filters__item.active::before {
    border-width: 0;
    left: 2px;
    top: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px); }
